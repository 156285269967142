// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-event-js": () => import("./../../../src/pages/event.js" /* webpackChunkName: "component---src-pages-event-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-news-js": () => import("./../../../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-people-js": () => import("./../../../src/pages/people.js" /* webpackChunkName: "component---src-pages-people-js" */),
  "component---src-pages-phd-courses-js": () => import("./../../../src/pages/phd-courses.js" /* webpackChunkName: "component---src-pages-phd-courses-js" */),
  "component---src-pages-publication-js": () => import("./../../../src/pages/publication.js" /* webpackChunkName: "component---src-pages-publication-js" */),
  "component---src-pages-recruitment-js": () => import("./../../../src/pages/recruitment.js" /* webpackChunkName: "component---src-pages-recruitment-js" */),
  "component---src-pages-research-js": () => import("./../../../src/pages/research.js" /* webpackChunkName: "component---src-pages-research-js" */),
  "component---src-templates-conference-template-js": () => import("./../../../src/templates/conferenceTemplate.js" /* webpackChunkName: "component---src-templates-conference-template-js" */),
  "component---src-templates-course-template-js": () => import("./../../../src/templates/courseTemplate.js" /* webpackChunkName: "component---src-templates-course-template-js" */),
  "component---src-templates-defences-template-js": () => import("./../../../src/templates/defencesTemplate.js" /* webpackChunkName: "component---src-templates-defences-template-js" */),
  "component---src-templates-markdown-template-js": () => import("./../../../src/templates/markdownTemplate.js" /* webpackChunkName: "component---src-templates-markdown-template-js" */),
  "component---src-templates-news-template-js": () => import("./../../../src/templates/newsTemplate.js" /* webpackChunkName: "component---src-templates-news-template-js" */),
  "component---src-templates-recruitments-template-js": () => import("./../../../src/templates/recruitmentsTemplate.js" /* webpackChunkName: "component---src-templates-recruitments-template-js" */),
  "component---src-templates-seminar-template-js": () => import("./../../../src/templates/seminarTemplate.js" /* webpackChunkName: "component---src-templates-seminar-template-js" */),
  "component---src-templates-staff-template-js": () => import("./../../../src/templates/staffTemplate.js" /* webpackChunkName: "component---src-templates-staff-template-js" */),
  "component---src-templates-workshop-template-js": () => import("./../../../src/templates/workshopTemplate.js" /* webpackChunkName: "component---src-templates-workshop-template-js" */)
}

